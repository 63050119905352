import gjs from "grapesjs";

function script() {
    eval(`
        (()=>{
            if (!this) return;
            if (this.getAttribute("data-gjs-type")) return;

            let isStTest = false;

            const btn = this.querySelector("button");
            const curLanguage = this.getAttribute("language");
            let dataToken = this.getAttribute("data-token");
            const dataTokenSelect = this.getAttribute("data-tokenSelect");
            const dataDomain = this.getAttribute("data-domain");
            const dataThankYou = this.getAttribute("data-thankyou");
            const dataArticle = this.getAttribute("data-article");

            const dataCustomButtonText = this.getAttribute("data-custombuttontext");
            if (dataCustomButtonText) btn.innerText = dataCustomButtonText;
    
            let FirstLastName = this.querySelector("input[name=FirstLastName]");
            let PhoneNumber = this.querySelector("input[name=PhoneNumber]");
            let errP = this.querySelector(".scalelead-form-err");

            const currentUrl = window.location.href;

            
            if(String(currentUrl).includes("sttest=1")){
                isStTest = true;
            }

            let quizQuestions = document.querySelectorAll(".quizQuestion");
            let quizAnswers = document.querySelectorAll(".quizAnswer");

            if(quizAnswers){
                quizAnswers.forEach(elem => {
                    elem.addEventListener("click", () => {
                        console.log("called mee --- ", elem)
                        elem.classList.add("quizAnswerSelected");    
                    })
                })
            }


            btn.onclick = () => {

                let quizComponentQuestions = document.querySelectorAll(".quizQuestionComponent");
                let quizComponentAnswers = document.querySelectorAll(".quizAnswerComponentSelected");
                let genericData = {};
                let selectedQuizAnswers;
                console.log("111", quizComponentQuestions)
                console.log("111", quizComponentAnswers)
                if(quizQuestions && quizAnswers){
                    selectedQuizAnswers = document.querySelectorAll(".quizAnswerSelected");

                    quizQuestions.forEach((keyNode, index) => {
                        const key = keyNode.textContent.trim();
                        const value = selectedQuizAnswers[index].textContent.trim();

                        genericData[key] = value;
                    })
                }
                if(quizComponentQuestions.length && quizComponentAnswers.length){
                    quizComponentQuestions.forEach((keyNode, index) => {
                        const key = keyNode.textContent.trim();
                        const value = quizComponentAnswers[index].textContent.trim();

                        genericData[key] = value;
                    })
                }

                errP.innerHTML = "";
                if (!FirstLastName.value && !PhoneNumber.value) {
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Proszę podać imię, nazwisko i numer telefonu";
                            return;
                        case "si":
                            errP.innerHTML = "Prosimo, vnesite ime, priimek in telefonsko številko";
                            return;
                        default:
                            errP.innerHTML = "Molimo unesite ime, prezime i broj telefona.";
                            return;
                    };
                };
                if (!FirstLastName.value && PhoneNumber.value) {
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Proszę podać imię i nazwisko.";
                            return;
                        case "si":
                            errP.innerHTML = "Prosimo, vnesite ime in priimek.";
                            return;
                        default:
                            errP.innerHTML = "Molimo unesite ime i prezime.";
                            return;
                    };
                };
                if (FirstLastName.value && !PhoneNumber.value) {
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Proszę podać numer telefonu.";
                            return;
                        case "si":
                            errP.innerHTML = "Prosimo, vnesite telefonsko številko.";
                            return;
                        default:
                            errP.innerHTML = "Molimo unesite broj telefona.";
                            return;
                    };
                };
                let pn = PhoneNumber.value.replace(/ /g, "").replace(/\\+/g, "").replace(/-/g, "").replace(/\\//g, "");
                if (isNaN(Number(pn)) || pn.length < 8 || pn.length > 14) {
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Numer telefonu jest nieprawidłowy!";
                            return;
                        case "si":
                            errP.innerHTML = "Telefonska številka ni veljavna!";
                            return;
                        default:
                            errP.innerHTML = "Broj telefona nije validan!";
                            return;
                    };
                };

                let formCompleted = window.localStorage.getItem("form-completed");
                if (formCompleted === "true") {
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Zauważyliśmy, że już złożyłeś wniosek o ten produkt. Obecnie przetwarzamy Twoje żądanie i skontaktujemy się z Tobą w najkrótszym możliwym czasie.";
                            return;
                        case "si":
                            errP.innerHTML = "Opazili smo, da ste že zaprosili za ta izdelek. Trenutno obdelujemo vašo zahtevo in vam bomo odgovorili v najkrajšem možnem času.";
                            return;
                        default:
                            errP.innerHTML = "Primijetili smo da ste već aplicirali za ovaj proizvod. Trenutno obrađujemo Vaš zahtjev i javit ćemo Vam se u najkraćem roku.";
                            return;
                    };  
                };
                if (!isNaN(Number(formCompleted))) {
                    if(FirstLastName.value !== 'test testic' ){
                        if ((Date.now() - Number(formCompleted)) <= 1000*60*60*12) {
                            switch (curLanguage) {
                                case "pl":
                                    errP.innerHTML = "Zauważyliśmy, że już złożyłeś wniosek o ten produkt. Obecnie przetwarzamy Twoje żądanie i skontaktujemy się z Tobą w najkrótszym możliwym czasie.";
                                    return;
                                case "si":
                                    errP.innerHTML = "Opazili smo, da ste že zaprosili za ta izdelek. Trenutno obdelujemo vašo zahtevo in vam bomo odgovorili v najkrajšem možnem času.";
                                    return;
                                default:
                                    errP.innerHTML = "Primijetili smo da ste već aplicirali za ovaj proizvod. Trenutno obrađujemo Vaš zahtjev i javit ćemo Vam se u najkraćem roku.";
                                    return;
                            }; 
                        };
                    }
                };
    
                if (dataTokenSelect !== "null" && dataDomain) {
                    dataToken = \`https://lp-\${dataTokenSelect}.\${dataDomain}\`;
                };

                let ad = {};
                if (window.sctrk) {
                    if (window.sctrk.getTrackingID) {
                        let tmp_id = window.sctrk.getTrackingID();
                        if (tmp_id) ad["scalelead-stclickid"] = tmp_id;
                    };
                };
    
                btn.style.display = "none";
                errP.innerHTML = \`Obrađujemo zahtjev, molimo sačekajte...<br/><img src="https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/90-ring-with-bg-black-36.svg" />\`;
                if(isStTest){
                    FirstLastName.value = 'test testic'
                }
                window.fetch(dataToken, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "scalelead-name": FirstLastName.value,
                        "scalelead-phone": pn,
                        "scalelead-quantity": 1,
                        "scalelead-uid": dataArticle,
                        "scalelead-c7": curLanguage.toUpperCase(),
                        "GenericData": genericData,
                        ...ad
                    })
                }).then(async (res) => {
                    try {
                        let tmp = JSON.parse(res.headers.get("status_json"));
                        if (tmp.status === "error" && tmp.data === "LEAD_REJECTED") {
                            switch (curLanguage) {
                                case "pl":
                                    errP.innerHTML = "Zauważyliśmy, że już złożyłeś wniosek o ten produkt. Obecnie przetwarzamy Twoje żądanie i skontaktujemy się z Tobą w najkrótszym możliwym czasie.";
                                    return;
                                case "si":
                                    errP.innerHTML = "Opazili smo, da ste že zaprosili za ta izdelek. Trenutno obdelujemo vašo zahtevo in vam bomo odgovorili v najkrajšem možnem času.";
                                    return;
                                default:
                                    errP.innerHTML = "Primijetili smo da ste već aplicirali za ovaj proizvod. Trenutno obrađujemo Vaš zahtjev i javit ćemo Vam se u najkraćem roku.";
                                    return;
                            };
                            return;
                        };
                    } catch {};
                    try {
                        let cStatus = await window.sctrk.convert({ integration_scalelead: res.headers.get("lead_id") });
                        if (cStatus === "error") {
                            try {
                                await window.sctrk.convert({track_error: JSON_stringify(cStatus)});
                            } catch {};
                            Sentry.captureMessage("TRACK_FAILED_ERROR", {
                                level: "error",
                                extra: cStatus
                            });
                        };
                    } catch (e) {
                        try {
                            await window.sctrk.convert({track_error: JSON_stringify(e, Object.getOwnPropertyNames(e))});
                        } catch {};
                        try {
                            Sentry.captureException(e);
                        } catch {};
                    };
                    let params = window.location.toString().split("?");
                    if (params.length > 1) params = params[1]; else params = "";
                    params += \`\${params ? "&" : ""}user_name=\${FirstLastName.value}&user_phone=\${pn}\`;
                    window.localStorage.setItem("form-completed", Date.now());
                    window.location.href = \`\${dataThankYou}\${params ? \`?\${params}\` : ""}\`;
                }).catch(() => {
                    btn.style.display = null;
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Proszę wprowadzić swoje dane";
                            return;
                        case "si":
                            errP.innerHTML = "Prosimo vnesite svoje podatke";
                            return;
                        default:
                            errP.innerHTML = "Molimo unesite Vaše podatke";
                            return;
                    };
                });
            };
        })()
    `)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, offers) => {
    editor.Components.addType('scalelead-form_cmp', {
        isComponent: e => e?.classList?.contains("scalelead-form"),
        model: {
            init() {
                this.on("change", e => {
                    let otherAttrs = {};

                    if (e?.attributes?.attributes?.["data-tokenselect"]) {
                        let curOffer = offers.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-tokenselect"]);
                        if (curOffer) {
                            otherAttrs["data-domain"] = curOffer.Domain;
                        } else {
                            otherAttrs["data-domain"] = null;
                        };
                    } else {
                        otherAttrs["data-domain"] = null;
                    };

                    this.addAttributes({ ...otherAttrs })
                    let language = e?.attributes?.attributes?.language;
                    let btnText = e?.attributes?.attributes["data-custombuttontext"]

                    this.get("components").each(child => {
                        if (child?.attributes?.attributes?.for === "FirstLastName") {
                            switch (language) {
                                case "pl":
                                    child.components("Imię i nazwisko");
                                    break;
                                case "si":
                                    child.components("Ime in priimek");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Ime i prezime");
                                    break;
                                default:
                                    break;
                            };
                        } else if (child?.attributes?.attributes?.for === "PhoneNumber") {
                            switch (language) {
                                case "pl":
                                    child.components("Numer telefonu");
                                    break;
                                case "si":
                                    child.components("Telefonska številka");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Broj telefona");
                                    break;
                                default:
                                    break;
                            };
                        } else if (child?.attributes?.attributes?.for === "orderBtn") {
                            console.log("scalelead form button change")
                            switch (language) {
                                case "pl":
                                    child.components("Zamówienie");
                                    break;
                                case "si":
                                    child.components("Oddajte naročilo");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Naruči");
                                    break;
                                default:
                                    break;
                            };
                            if(btnText){
                                child.components(btnText);
                            }
                        } else if (["FirstLastName", "PhoneNumber"].includes(child?.attributes?.attributes?.name)) {
                            let curChildName = child?.attributes?.attributes?.name;
                            switch (language) {
                                case "pl":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Imię i nazwisko *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Numer telefonu *" });
                                    };
                                    break;
                                case "si":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Ime in priimek *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Telefonska številka *" });
                                    };
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Ime i prezime *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Broj telefona *" });
                                    };
                                default:
                                    break;
                            };
                        };
                    });
                });
            },
            defaults: {
                type: "default",
                traits(component) {
                    let offerData = offers.map(o => {
                        return {
                            id: o.UniqueToken,
                            name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
                        };
                    }).sort((a, b) => {
                        return String(a.name).localeCompare(b.name);
                    });
                    offerData = [{ id: null, name: "Not selected" }, ...offerData];

                    return [
                        { name: "data-token", label: "Scalelead URL", placeholder: "https://lp-*****.scalelead.com...", value: "" },
                        { name: "data-tokenselect", label: "Scalelead Offer", type: "select", options: offerData, value: null },
                        { name: "data-article", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
                        { name: "data-thankyou", label: "Thank you page", placeholder: "./thankyou...", value: "/thankyou" },
                        {
                            name: "language", type: "select", options: [
                                { id: "custom", name: "Custom" },
                                { id: "ba", name: "Bosanski", selected: true },
                                { id: "rs", name: "Srpski" },
                                { id: "hr", name: "Hrvatski" },
                                { id: "pl", name: "Poljski" },
                                { id: "si", name: "Slovenski" },
                                { id: "me", name: "Crnogorski" }
                            ], value: "ba"
                        },
                        { name: "data-custombuttontext", label: "Custom button text", placeholder: "Buy now, Order now...", value: "" },
                    ]
                },
                attributes: {
                    class: "scalelead-form"
                },
                script,
                styles: `
                    .scalelead-form {
                        display: flex;
                        flex-direction: column;
                        max-width: 320px;
                        margin: 0 auto;
                        padding: 20px;
                        font-family: 'Roboto Condensed',sans-serif;
                    }
                    .scalelead-form > label {
                        margin-bottom: 10px;
                    }
                    .scalelead-form > input {
                        margin-bottom: 20px;
                    }
                `,
                components: [
                    {
                        type: "label",
                        attributes: { for: "FirstLastName" },
                        components: "Ime i prezime",
                        removable: false
                    },
                    {
                        type: "input",
                        attributes: { type: "text", name: "FirstLastName", placeholder: "Ime i prezime" },
                        removable: false
                    },

                    {
                        type: "label",
                        attributes: { for: "PhoneNumber" },
                        components: "Broj telefona",
                        removable: false
                    },
                    {
                        type: "input",
                        attributes: { type: "text", name: "PhoneNumber", placeholder: "Broj telefona" },
                        removable: false
                    },
                    {
                        type: "button",
                        attributes: { for: "orderBtn" },
                        components: "Naruči",
                        removable: false,
                        traits: []
                    },
                    {
                        type: "text",
                        attributes: { class: "scalelead-form-err" },
                        components: ""
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalelead-form_blk', {
        label: 'Scale-lead form',
        content: {
            type: "scalelead-form_cmp",
        },
        category: {
            label: 'Interactions'
        },
        media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
        attributes: {
            title: 'Insert a Scale-lead form block',
        },
        select: true
    });
};