import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(() => {
    const questions = document.querySelectorAll('.scalecms-question');
    const startPage = document.querySelector('.scalecms-start-page');
    const finalPage = document.querySelector('.scalecms-quizzie-bottom');
    const showStartPage = this.getAttribute("data-show-start");

    let quizQuestions = document.querySelectorAll(".quizQuestionComponent");
    let quizAnswers = document.querySelectorAll(".quizAnswerComponent");

    if(quizAnswers){
      quizAnswers.forEach(elem => {
        elem.addEventListener("click", () => {
          elem.classList.add("quizAnswerComponentSelected");    
        })
      })
    }

    let currentQuestionIndex = 0;

    // Hide all questions initially
    questions.forEach((question, index) => {
      question.style.opacity = '0';
      question.style.display = 'none';
    });

    function showNextQuestion() {
      if (currentQuestionIndex < questions.length - 1) {
        const currentQuestion = questions[currentQuestionIndex];
        currentQuestion.style.opacity = '0';
        setTimeout(() => {
          currentQuestion.style.display = 'none';
          currentQuestionIndex++;
          const nextQuestion = questions[currentQuestionIndex];
          nextQuestion.style.display = 'block';
          setTimeout(() => {
            nextQuestion.style.opacity = '1';
          }, 50);
        }, 500);
      } else {
        const currentQuestion = questions[currentQuestionIndex];
        currentQuestion.style.maxHeight = '0px';
        currentQuestion.style.overflow = 'hidden';
        finalPage.classList.add("scalecms-quizzie-bottom--active");
        finalPage.style.padding = '20px';
        finalPage.style.maxHeight = '9999px';
      }
    }

    function startQuiz() {
      if (startPage) {
        startPage.style.opacity = '0';
        setTimeout(() => {
          startPage.style.display = 'none';
          if (questions.length > 0) {
            questions[0].style.display = 'block';
            setTimeout(() => {
              questions[0].style.opacity = '1';
            }, 50);
          }
        }, 500);
      }
    }

    if (showStartPage) {
      startPage.style.display = 'block';
      const startButton = document.querySelector('.scalecms-start-button');
      if (startButton) {
        if (this.getAttribute("data-gjs-type")) return;
        startButton.addEventListener('click', startQuiz);
      }
    } else {
      startQuiz();
    }

    document.querySelectorAll('.scalecms-answer').forEach(answer => {
      answer.addEventListener('click', showNextQuestion);
    });
  })()`);

}

/**
 * @param {gjs.Editor} editor
 */
export default (editor) => {
  editor.Components.addType('scalecms-quizzie', {
    isComponent: e => e?.classList?.contains("scalecms-quizzie"),
    model: {
      init() {
        this.on("change:attributes:data-questions", this.updateQuestions);
        this.on("change:attributes:data-show-start", this.updateStartPage);
      },
      defaults: {
        script,
        type: "default",
        traits(component) {
          return [
            { name: "data-questions", label: "Amount of Questions", placeholder: "5", value: "" },
            {
              type: "button", text: "Open Last Page", full: true, command: editor => {
                let el = editor.getSelected().getEl();
                let finalPage = el.querySelector('.scalecms-quizzie-bottom');
                finalPage.classList.add("scalecms-quizzie-bottom--active");
              }
            },
            {
              type: "button", text: "Close Last Page", full: true, command: editor => {
                let el = editor.getSelected().getEl();
                let finalPage = el.querySelector('.scalecms-quizzie-bottom');
                finalPage.classList.remove("scalecms-quizzie-bottom--active");
              }
            },
            {
              name: "data-show-start", label: "Show first page", type: "select", options: [
                  { id: "true", name: "yes" },
                  { id: "false", name: "no" }
              ], value: "true"
            },
            {
              type: "button",
              text: "Toggle Questions & Answers",
              full: true,
              command: editor => {
                let el = editor.getSelected().getEl();
                const questions = el.querySelectorAll('.scalecms-question');

                const areQuestionsVisible = questions.length && questions[0].style.display !== 'none';

                questions.forEach(questionEl => {
                  questionEl.style.display = areQuestionsVisible ? 'none' : 'block';
                  questionEl.style.opacity = areQuestionsVisible ? '0' : '1';
                });
              }
            }
          ];
        },
        attributes: {
          class: "scalecms-quizzie"
        },
        styles: `
          .scalecms-quizzie{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 800px;
            box-shadow: 0px 0px 5px 3px rgba(34,34,34,0.75);
            background-color: white;
            position: relative;
            padding: 20px;
            margin: 0 auto;
            scroll-margin: 20px;
            z-index: 3;
          }

          .scalecms-quizzie-top{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 30px;
          }
          .scalecms-quizzie-top-headline{
            text-align: center;
          }
          .scalecms-quizzie-top-subheadline{
            text-align: center;
          }

          .scalecms-start-page {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            opacity: 1;
            transition: opacity 0.5s ease; /* Smooth transition for opacity */
          }

          .scalecms-start-button {
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            margin: 0 auto;
            margin-top: 10px;
            display: block;
          }
          
          .scalecms-start-button:hover {
            background-color: #0056b3;
          }

          .scalecms-quizzie-bottom{
            padding: 0px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: auto;
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s ease;
          }
          .scalecms-quizzie-bottom--active{
            padding: 20px !important;
            max-height: 1000px !important;
          }

          .scalecms-question {
            margin: 0 auto;
            margin-bottom: 20px;
            opacity: 1;
            text-align: center;
            transition: opacity 0.5s ease; /* Smooth transition for opacity */
          }

          .scalecms-answer {
            margin: 5px 0;
            padding: 20px;
            background-color: #f0f0f0;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          
          .scalecms-answer:hover {
            background-color: #dbd7d7;  /* Slight highlight on hover */
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: 'scalecms-quizzie-top' },
            components: [
              {
                type: 'text',
                attributes: { class: 'scalecms-quizzie-top-headline' },
                components: 'Headline Text'
              },
              {
                type: 'text',
                attributes: { class: 'scalecms-quizzie-top-subheadline' },
                components: 'Subheadline'
              }
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'scalecms-start-page' },
            components: [
              {
                type: 'text',
                attributes: { class: 'scalecms-quizzie-top-subheadline' },
                components: 'Započnite kviz'
              },
              {
                tagName: 'button',
                attributes: { class: 'scalecms-start-button' },
                components: 'Start Quiz'
              }
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'scalecms-quizzie-body' },
            components: []  // Questions will be dynamically injected here
          },
          {
            tagName: 'div',
            attributes: { class: 'scalecms-quizzie-bottom' },
            components: [
              {
                type: 'text',
                attributes: { class: 'scalecms-quizzie-bottom-text' },
                components: 'Content for quiz completion page'
              }
            ]
          }
        ]
      },
      
      // Method to update questions based on the data-questions trait
      updateQuestions() {
        const questionsCount = this.get('attributes')['data-questions'] || 0;
        const bodyComponent = this.find('.scalecms-quizzie-body')[0];

        if (bodyComponent) {
          bodyComponent.components().reset();  // Clear existing components
          for (let i = 1; i <= questionsCount; i++) {
            bodyComponent.append(`
              <div class="scalecms-question">
                <p class="quizQuestionComponent">Question ${i}:</p>
                <div class="scalecms-answers">
                  <p class="quizAnswerComponent scalecms-answer a${i}-1">Answer A</p>
                  <p class="quizAnswerComponent scalecms-answer a${i}-2">Answer B</p>
                  <p class="quizAnswerComponent scalecms-answer a${i}-3">Answer C</p>
                  <p class="quizAnswerComponent scalecms-answer a${i}-4">Answer D</p>
                </div>
              </div>
            `);
          }
        }
      },
      updateStartPage() {
        const showStartPage = this.get('attributes')['data-show-start'];

        const startPageComponent = this.find('.scalecms-start-page')[0];

        if (startPageComponent) {
          const style = startPageComponent.get('style') || {};
          style.display = showStartPage ? 'flex' : 'none';
          startPageComponent.set('style', style);
        }
      }
    }
  });

  editor.BlockManager.add('scalecms-quizzie-blk', {
    label: 'Quizzie',
    content: {
      type: "scalecms-quizzie",
    },
    category: {
      label: 'Quizzie'
    },
    media: ``,
    attributes: {
      title: 'Quizzie',
    },
    select: true
  });
};